
import { Vue, Component, Ref } from "vue-property-decorator";
import { InvitationListDataSource } from "@/data/List/InvitationListDataSource";
import DataList from "@/components/common/data/list.vue";
import userModule from "@/store/modules/user";
import { instaceStates } from "@/cms-services/consts";
import { Filter } from "@/cms-services/filter";
import {UserObjectDataSource} from "@/data/Object/UserObjectDataSource";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
@Component({
  components: {
    DataList,
  },
})
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;
  instaceStates = instaceStates;
  $message: any;
  dataSource: InvitationListDataSource = new InvitationListDataSource({
    pageIndex: 1,
    pageSize: 100,
    filter: JSON.stringify([
      new Filter("userProfileId", +this.$route.params.id),
    ]),
  });

  userProfileDataSource: ObjectDataSource = new ObjectDataSource({
    className:"UserProfile",
    id: +this.$route.params.id,
    config:{}
  })

  createItemModel: any = {
    userProfileId: +this.$route?.params.id,
    templatePath: "Mail/Invite",
    subject: "Приглашение в систему",
    firstName: "",
    lastName: "",
    email: "",
  };

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Статистика по приглашениям",
        to: "/manage/statistics",
      },
      {
        text: this.userProfileDataSource.model?.fullName,
        disabled:true
      },
    ];
  }

  getInstaceStateColor(instanceStateId) {
    switch (instanceStateId) {
      case 200:
        return "info";
      case 1:
        return "warning";
      case 11:
        return "success";
      default:
        return "default";
    }
  }

  async created(){
    await this.userProfileDataSource.get();
  }

  async invite(item) {
    this.dataList.loading = true;
    await this.dataSource.invite(item.id);
    this.dataList.loading = false;
    item.instanceStateId = 200;
    this.$message(`Приглашение успешно отправлено на почту ${item.email}`);
  }

  async setInstanceState(item, event) {
    await this.dataSource.updateField(item.id, [
      {
        fieldName: "instanceStateId",
        fieldValue: event,
      },
    ]);
  }
}
